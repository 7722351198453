import {connect} from "react-redux";
import React, {Component} from "react";
import {rejectIfAnyNull} from "../controllers/utilities/env-checks";
import {Button} from "semantic-ui-react";
import {formatQuery} from "../controllers/utilities/string-utils";
import {navigate} from "../../.cache/gatsby-browser-entry";
import cookie from "react-cookies";
import Helmet from "react-helmet";

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings,
    };
})(class extends Component {
    constructor(props, context) {
        super(props, context);
        // let {transactionId} = formatQuery(this.props.location.search);
        console.log(this.props.transactionId);
        console.log(this.props.session);
        // rejectIfAnyNull(this.props.session, this.props.transactionId);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.getDirection);
    }

    getDirection = () => {
        this.setState({ state: this.state });
    }

    async componentDidMount() {
        window.addEventListener('resize', this.getDirection);
    }

    onGameSupport() {
        const {transactionId} = formatQuery(this.props.location.search);
        // navigate(`/game-support/?transactionId=${transactionId}`);
        navigate(`/payment-support-addticket/?transactionId=${transactionId}`);
    }

    onPaymentSupportResult() {
        const {transactionId} = formatQuery(this.props.location.search);
        navigate(`/payment-support-result/?transactionId=${transactionId}`);
    }

    onPaymentSupportAddTicket() {
        const {transactionId} = formatQuery(this.props.location.search);
        navigate(`/payment-support-addticket/?transactionId=${transactionId}`);
    }

    onContinue() {
        let {pendingTransactionId, newAccessToken, newGameId, newAttributionId} = formatQuery(this.props.location.search);
        if (((newAccessToken === undefined) && (newAccessToken !== null)) || ((newGameId === undefined) && (newGameId !== null))){
            newAccessToken = cookie.load("_accessToken");
            newGameId = cookie.load("_gameId");
            newAttributionId = cookie.load("_attributionId");
            pendingTransactionId = cookie.load("_transactionId");
        }
        if ((newAccessToken !== undefined) && (newGameId !== undefined) && (newAccessToken !== null) && (newGameId !== null)){
            let platform = cookie.load("_platform");
            const url = `/confirm-purchase/?gameId=${newGameId}&attributionId=${newAttributionId}&transactionId=${pendingTransactionId}&accessToken=${newAccessToken}&platform=${platform}`;
            // console.log(url);
            window.location.href = url;
        }else {
            navigate("/confirm-purchase");
        }
    }

    onReturn(){
        const {transactionId} = formatQuery(this.props.location.search);
        navigate(`/credit-purchase-result/?transactionId=${transactionId}`);
    }

    render() {
        const mode = detectOrient();
        return (
            <div style={{
                minHeight: '100vh',
                justifyContent: 'center',
                flexDirection: 'column',
                backgroundColor:"#F8F8F6"
            }}>
                <Helmet>
                    <style>{'body:before { background-image:none; background-color: #F8F8F6; }'}</style>
                </Helmet>
                {(mode === "PORTRAIT") && (<>
                 <div style={{width:"100%",height:"100vh", margin:"0 auto"}}>
                     <div style={{padding:"20px", fontSize:"1.3rem", fontWeight:"bold"}}>
                     聯繫商家解決
                     </div>
                     <div style={{width:"90vw",marginLeft:"5vw", fontSize:"1.3rem", backgroundColor:"#fff", textAlign:"left", padding:"8px", color:"#888", borderRadius: '0.5rem'}}>
                         <div style={{paddingTop:"10px", paddingBottom:"10px"}}><p>
                     如存在交易爭執、要求退款等訴求，直接聯繫收款方溝通處理是最有效的方式
                         </p></div>
                         <div style={{textAlign:"center", color:"#636E80", borderTop:"1px solid #F3F3F3", padding:"10px"}}
                         onClick={this.onGameSupport.bind(this)}
                         >聯繫商家</div>
                         </div>
                     <div style={{padding:"20px", fontSize:"1.3rem", fontWeight:"bold"}}>
                         投訴與舉報
                     </div>
                     <div style={{width:"90vw",marginLeft:"5vw", fontSize:"1.3rem", textAlign:"left",  color:"#333"}}>
                         <div style={{ backgroundColor:"#fff",width:"43vw", height:"25vw",float:"left" ,borderRadius: '0.5rem', textAlign:"center", verticalAlign:"middle", paddingTop:"10vw"}}
                              onClick={this.onPaymentSupportResult.bind(this)}
                         >
                             商家未收到付款
                         </div>
                         <div style={{ backgroundColor:"#fff",width:"43vw", height:"25vw", float:"right" ,borderRadius: '0.5rem', textAlign:"center", verticalAlign:"middle", paddingTop:"10vw"}}
                              onClick={this.onPaymentSupportAddTicket.bind(this)}
                         >
                             涉嫌詐騙
                         </div>
                         <div style={{ marginTop:"4vw", backgroundColor:"#fff",width:"43vw", height:"25vw",float:"left" ,borderRadius: '0.5rem', textAlign:"center", verticalAlign:"middle", paddingTop:"10vw"}}
                              onClick={this.onPaymentSupportAddTicket.bind(this)}
                         >
                             不滿意商家服務
                         </div>
                         <div style={{ marginTop:"4vw", backgroundColor:"#fff",width:"43vw", height:"25vw", float:"right" ,borderRadius: '0.5rem', textAlign:"center", verticalAlign:"middle", paddingTop:"10vw"}}
                              onClick={this.onPaymentSupportAddTicket.bind(this)}
                         >
                             其他問題
                         </div>
                     </div>
                     <div style={{width:"90vw",marginLeft:"5vw", paddingTop:"55vw"}}>

                         <Button fluid
                                 style={{marginTop: '1rem'}}
                                 onClick={this.onReturn.bind(this)}
                         >
                             返回帳單詳情
                         </Button>
                     </div>
                 </div>
                </>)}

                {(mode === "LANDSCAPE") && (<>
                    <div style={{float:"left", width:"50%",height:"100vh"}}>
                        <div style={{padding:"20px", fontSize:"1.3rem", fontWeight:"bold"}}>
                            聯繫商家解決
                        </div>
                        <div style={{width:"40vw",marginLeft:"5vw", fontSize:"1.1rem", backgroundColor:"#fff", textAlign:"left", padding:"8px", color:"#888", borderRadius: '0.5rem'}}>
                            <div style={{paddingTop:"10px", paddingBottom:"10px"}}><p>
                                如存在交易爭執、要求退款等訴求，直接聯繫收款方溝通處理是最有效的方式
                            </p></div>
                            <div style={{textAlign:"center", color:"#636E80", borderTop:"1px solid #F3F3F3", padding:"4px"}}
                                 onClick={this.onGameSupport.bind(this)}
                            >聯繫商家</div>
                        </div>
                        <div style={{width:"40vw",marginLeft:"5vw"}}>

                            <Button fluid
                                    style={{marginTop: '1rem'}}
                                    onClick={this.onReturn.bind(this)}
                            >
                                返回帳單詳情
                            </Button>
                        </div>
                    </div>
                    <div style={{float:"right",width:"50%",height:"100vh"}}>
                        <div style={{padding:"20px", fontSize:"1.3rem", fontWeight:"bold"}}>
                            投訴與舉報
                        </div>
                        <div style={{width:"45vw",margin:"1vh", fontSize:"1.1rem", textAlign:"left",  color:"#333"}}>
                            <div style={{ backgroundColor:"#fff",width:"21vw", height:"15vw",float:"left" ,borderRadius: '0.5rem', textAlign:"center", verticalAlign:"middle", paddingTop:"7vw"}}
                                 onClick={this.onPaymentSupportResult.bind(this)}
                            >
                                商家未收到付款
                            </div>
                            <div style={{ backgroundColor:"#fff",width:"21vw", height:"15vw", float:"right" ,borderRadius: '0.5rem', textAlign:"center", verticalAlign:"middle", paddingTop:"7vw"}}
                                 onClick={this.onPaymentSupportAddTicket.bind(this)}
                            >
                                涉嫌詐騙
                            </div>
                            <div style={{ marginTop:"2vw", backgroundColor:"#fff",width:"21vw", height:"15vw",float:"left" ,borderRadius: '0.5rem', textAlign:"center", verticalAlign:"middle", paddingTop:"7vw"}}
                                 onClick={this.onPaymentSupportAddTicket.bind(this)}
                            >
                                不滿意商家服務
                            </div>
                            <div style={{ marginTop:"2vw", backgroundColor:"#fff",width:"21vw", height:"15vw", float:"right" ,borderRadius: '0.5rem', textAlign:"center", verticalAlign:"middle", paddingTop:"7vw"}}
                                 onClick={this.onPaymentSupportAddTicket.bind(this)}
                            >
                                其他問題
                            </div>
                        </div>
                    </div>

                </>)}
            </div>
        );
    }


});


function detectOrient(){
    if(typeof window !== `undefined`) {
        // let isIOS = "0";
        // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
        //     // 暂时去掉ios两板功能
        //     isIOS = "1";
        // }
        // if (isIOS === "0") return "PORTRAIT";
        if (window.innerHeight >= window.innerWidth) {
            // 竖屏
            // console.log("竖屏");
            return "PORTRAIT";
        } else {
            // 横屏
            // console.log("横屏");
            return "LANDSCAPE";
        }
    }else{
        return "PORTRAIT";
    }
}